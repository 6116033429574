import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Src/components/PageLoader'
import api from 'Src/services/api'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'

import ConnectGitBook from './ConnectGitBook'
import GitBookAuthForm from './GitBookAuthForm'
import ManageGitBook from './ManageGitBook'

const GitBook = () => {
  const { currentWorkspaceId } = useWorkspace()
  const { isLoading, data: app = {} } = api.useGetGitBookAppQuery({ workspace_id: currentWorkspaceId })
  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <ConnectGitBook app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<GitBookAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageGitBook app={app} />} />
    </Routes>
  )
}

export default GitBook
