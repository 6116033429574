import styles from './Product.module.css'

import PoweredByHappyfoxLogo from 'Images/powered-by-happyfox.svg'

const Product = () => {
  return (
    <div className={styles.ProductContainer}>
      <PoweredByHappyfoxLogo className={styles.ProductLogo} />
    </div>
  )
}

export default Product
