import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Src/components/ConfirmationModal'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'

import AppFooter from '../../../components/AppFooter'
import { ConnectedAppBase } from '../../../components/Common/CommonUpgrade'
import SyncDisabledAlert from '../../../components/SyncDisabledAlert'
import KnowledgeConfiguration from '../../../KnowledgeConfiguration'
import { useAppDetailContext } from '../../AppDetailContext'
import GitBookSpaces from './GitBookSpaces'

const { default: api } = require('Src/services/api')

const ManageGitBook = (props) => {
  const { app } = props
  const { title } = useAppDetailContext()
  const { currentWorkspaceId } = useWorkspace()

  const [disconnectGitBook, disconnectGitBookResult] = api.useDisconnectGitBookAppMutation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [enableSync, enableSyncResult] = api.useEnableSyncGitBookMutation()
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)

  const handleUninstall = () => {
    const promise = disconnectGitBook({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsModalOpen(false))

    toast.promise(promise, {
      loading: `Uninstalling ${title}`,
      success: `Successfully uninstalled ${title}`,
      error: parseErrorMessage(`Unable to uninstall ${title}. Try again...`)
    })
  }

  const handleEnableSync = () => {
    if (enableSyncResult.isLoading) return

    const promise = enableSync({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, {
      loading: `Enabling sync for ${title}`,
      success: `Successfully enabled sync for ${title}`,
      error: parseErrorMessage(`Unable to enable sync for ${title}. Try again...`)
    })
  }

  const isSyncDisabled = !app.sync_info.is_sync_enabled

  return (
    <Fragment>
      {isSyncDisabled && <SyncDisabledAlert onEnableSync={() => setIsEnableSyncModalOpen(true)} />}
      <ConnectedAppBase>
        <Heading level={2}>
          Successfully connected to {title} workspace. <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {app.user_email_id}
          </Text>
        </Heading>
      </ConnectedAppBase>
      <GitBookSpaces app={app} />
      <KnowledgeConfiguration app={app} />
      <AppFooter
        app={app}
        onUninstall={() => setIsModalOpen(true)}
        isUninstalling={disconnectGitBookResult.isLoading}
      />
      <ConfirmationModal
        isOpen={isModalOpen}
        title={`Uninstall from ${title}`}
        message={`Are you sure you want to uninstall ${title}?`}
        onConfirm={handleUninstall}
        onCancel={() => setIsModalOpen(false)}
        isConfirming={disconnectGitBookResult.isLoading}
      />
      <ConfirmationModal
        isOpen={isEnableSyncModalOpen}
        title={`Enable Sync for ${title}`}
        message={`Are you sure you want to enable sync for ${title}?`}
        onConfirm={handleEnableSync}
        onCancel={() => setIsEnableSyncModalOpen(false)}
      />
    </Fragment>
  )
}

export default ManageGitBook
