import parseErrorMessage from 'Utils/error-message-parser'

const getUninstallWarningForUserGroupApps = (appName) =>
  `Users imported from ${appName} will be removed from user groups. Do you want to proceed?`

export const CONFIRM_MESSAGES = {
  LOGOUT: 'Are you sure to logout?',
  UNINSTALL_OKTA: getUninstallWarningForUserGroupApps('Okta'),
  UNINSTALL_AZURE_AD: getUninstallWarningForUserGroupApps('Azure Active Directory'),
  UNINSTALL_APP: 'Are you sure to uninstall this app?',
  DISCONNECT_APP: 'Are you sure to disconnect this app?',
  DELETE_ADMIN: 'Are you sure to delete this admin?',
  DELETE_ANSWER: 'Are you sure to delete this answer?',
  REMOVE_PAGE_SYNC: 'Are you sure to remove this page from syncing?',
  REDIRECT_TO_URL: (domain) => `Are you sure to redirect to this URL? URL: ${domain}`,
  ENABLE_APP_SYNC: (appName) => `Do you want to enable sync for ${appName} ?`,
  HF_ACCOUNT_EXPIRED_ALERT: (appName) =>
    `Your ${appName} account has expired. Please contact your HappyFox account executive to activate your account. Once activated, enable the sync.`,
  SEND_ANNOUNCEMENT: 'Assist AI will send this message as DM to all the employees.',
  ANNOUNCEMENT_IN_PROGRESS: 'Announcement triggered successfully',
  ANNOUNCEMENT_SUCCESS: (
    <span>
      <div style={{ textAlign: 'center' }}>🎉</div>
      <div>Announcement sent successfully</div>
    </span>
  ),
  DEFAULT_PROMPT_MESSAGE: 'You will lose the unsaved changes. Are you sure you want to leave this page?',
  DELETE_ACTION: 'Are you sure to delete this action?',
  DELETE_WORKSPACE: 'Are you sure to delete this workspace?',
  REMOVE_SPACE_SYNC: 'Are you sure to remove this space from syncing?'
}

export const TOAST_MESSAGES = {
  ENABLE_SYNC: (title) => ({
    loading: `Enabling sync for ${title}`,
    success: `${title} sync enabled successfully. Come back later to check sync status.`,
    error: parseErrorMessage(`Could not enable sync for ${title}. Try again`)
  }),
  EXPORT_REPORTS_LOADING: 'Exporting reports...',
  EXPORT_REPORTS_ERROR: 'Failed to export reports. Please try again.',
  EXPORT_REPORTS: (fromDate, toDate, account_type, workspace = null, topic = null) => ({
    success: `Report export for the period ${fromDate}, to ${toDate}${
      workspace ? `, within the ${workspace.toLowerCase()} workspace` : ''
    }${
      topic ? ` under the ${topic.toLowerCase()} topic` : ''
    } has been initiated. The report will be sent to your ${account_type.toLowerCase()} shortly.`
  })
}
