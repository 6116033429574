import { FormErrorText, Heading, PaginationControls, TableActions, Text, Th } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'
import * as yup from 'yup'

import styles from './GitBookSpaces.module.scss'

import { SecondaryButton } from 'Src/components/Buttons'
import PageLoader from 'Src/components/PageLoader'
import ReactSelect from 'Src/components/ReactSelect'
import api from 'Src/services/api'
import debounce from 'Src/utils/debounce'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'

import GitBookSpacesOptions from './GitBookSpacesOptions'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}

const SpacesList = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))

  const { isLoading, data = {} } = api.useGetCurrentlySyncingGitBookSpacesQuery(queryParams)
  const { results: spaces = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Space Name',
        accessor: 'title'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: spaces,
      initialState: { pageIndex: 0, pageSize: PAGE_SIZE },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [currentWorkspaceId, debouncedSetQueryParams, pageIndex])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Fragment>
      <Heading level={2}>Spaces currently syncing from</Heading>
      <div className={styles.TableContainer}>
        <TableActions>
          <PaginationControls
            currentPage={pageIndex + 1}
            pageSize={PAGE_SIZE}
            totalItems={paginationDetails.total}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </TableActions>
        <table {...getTableProps()} className={styles.Table}>
          <thead className={styles.Th}>
            {headerGroups.map((headerGroup) => {
              const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

              return (
                <tr key={key} {...headerGroupProps}>
                  <Fragment>
                    {headerGroup.headers.map((column) => {
                      const { key, ...headerProps } = column.getHeaderProps()

                      return (
                        <th className={styles.Th} key={key} {...headerProps}>
                          {column.render('Header')}
                        </th>
                      )
                    })}
                    <Th isOption />
                  </Fragment>
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const { key, ...cellProps } = cell.getCellProps()

                    return (
                      <td className={styles.Td} key={key} {...cellProps}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                  <td className={styles.Td}>
                    <GitBookSpacesOptions id={row.original.space_id} />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className={styles.NoSpaces}>{spaces.length === 0 && <Text>No spaces found</Text>}</div>
      </div>
    </Fragment>
  )
}

export const gitBooksFormValidationSchema = yup.object().shape({
  space_name: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .required('Space is required')
})

const GitBookSpaces = () => {
  const { currentWorkspaceId } = useWorkspace()
  const { data } = api.useGetAvailableGitBookSpacesQuery({ workspace_id: currentWorkspaceId })
  const [addSpaceToSync, addSpaceToSyncResult] = api.useAddSpaceToSyncMutation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      space_name: null
    },
    resolver: yupResolver(gitBooksFormValidationSchema)
  })

  const spacesOptions = data?.map((space) => ({
    value: space.id,
    label: space.title
  }))

  const customStyles = {
    control: (styles) => ({
      ...styles,
      boxShadow: 'none',
      borderColor: errors.space_name ? '#f00' : '#ccc',
      '&:hover': {
        borderColor: errors.space_name ? '#f00' : 'var(--primary-solid)'
      }
    })
  }

  const handleAddSpace = async (data) => {
    const payload = {
      workspace_id: currentWorkspaceId,
      space_id: data.space_name.value
    }

    try {
      const promise = addSpaceToSync(payload).unwrap()

      toast.promise(promise, {
        loading: 'Adding space to sync',
        success: 'Space added to sync successfully',
        error: 'Failed to add space to sync. Try again...'
      })
      await promise
      reset()
    } catch {}
  }

  return (
    <Fragment>
      <div className={styles.AddSpaceContainer}>
        <Heading level={2}>Add a GitBook space to sync</Heading>
        <form onSubmit={handleSubmit(handleAddSpace)} className={styles.AddSpaceForm}>
          <Controller
            control={control}
            name='space_name'
            render={({ field }) => (
              <ReactSelect
                {...field}
                styles={customStyles}
                className={styles.AddSpaceDropdown}
                options={spacesOptions}
                placeholder='Select a space'
                isSearchable
                isClearable
              />
            )}
          />
          <SecondaryButton type='submit' disabled={addSpaceToSyncResult || isSubmitting}>
            Add +
          </SecondaryButton>
        </form>
        {errors.space_name && <FormErrorText>{errors.space_name.message}</FormErrorText>}
      </div>
      <SpacesList />
    </Fragment>
  )
}

export default GitBookSpaces
