import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Src/components/ConfirmationModal'
import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import api from 'Src/services/api'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'

const GitBookSpacesOptions = ({ id }) => {
  const { currentWorkspaceId } = useWorkspace()
  const [removeSpace] = api.useRemoveSpaceFromSyncMutation()

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  const handleRemoveClick = useCallback(() => {
    const promise = removeSpace({ space_id: id, workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsRemoveModalOpen(false))

    toast.promise(promise, {
      loading: 'Removing space...',
      success: 'Space removed successfully.',
      error: parseErrorMessage('Unable to remove space. Try again...')
    })
  }, [removeSpace, id, currentWorkspaceId])

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => setIsRemoveModalOpen(true)}>Remove</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isRemoveModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.REMOVE_SPACE_SYNC}
        onCancel={() => setIsRemoveModalOpen(false)}
        onConfirm={handleRemoveClick}
      />
    </Fragment>
  )
}

export default GitBookSpacesOptions
