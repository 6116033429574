// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DMYowtniBHg_lzBYUz8E{padding-left:1rem;margin-top:auto;margin-bottom:.5rem}.zFao1TUE4Joz0CsnC3mG{width:60%;height:80%}", "",{"version":3,"sources":["webpack://./../src/components/Sidebar/Product.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA,CACA,mBAAA,CAGF,sBACE,SAAA,CACA,UAAA","sourcesContent":[".ProductContainer {\n  padding-left: 1rem;\n  margin-top: auto;\n  margin-bottom: 0.5rem;\n}\n\n.ProductLogo {\n  width: 60%;\n  height: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductContainer": "DMYowtniBHg_lzBYUz8E",
	"ProductLogo": "zFao1TUE4Joz0CsnC3mG"
};
export default ___CSS_LOADER_EXPORT___;
