// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wPmkXj7ACUBw19UaF2dv{margin-top:-2.3rem}.i32YheiFqzahVPb5XMae{width:100%;border-collapse:collapse;overflow:hidden;border-radius:var(--border-radius, 6px);box-shadow:0 0 3px 0 rgba(0,0,0,.2);margin-bottom:1rem}.T6Z7mBDfin81edVhFKgQ,.j7iEPS0AIlLrfdLaHSzK,.lRp6DBd_QlSexPcHFhEy{padding:.5rem;text-align:inherit;text-align:-webkit-match-parent}.lRp6DBd_QlSexPcHFhEy{background-color:#eee}.jnAuQI56KzUUQTL_JPjv{text-align:center}.j1of7YHmPSl_kdBTUQb_{margin:1rem 0}.vsCersdkOx96fAQJjxUv{display:flex;gap:1rem}.gs7_vdFEuKDfHs_xMriu{width:50%}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/AppDetails/apps/GitBook/GitBookSpaces/GitBookSpaces.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,uCAAA,CACA,mCAAA,CACA,kBAAA,CAGF,kEACE,aAAA,CACA,kBAAA,CACA,+BAAA,CAGF,sBAEE,qBAAA,CAOF,sBACE,iBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,SAAA","sourcesContent":[".TableContainer {\n  margin-top: -2.3rem;\n}\n\n.Table {\n  width: 100%;\n  border-collapse: collapse;\n  overflow: hidden;\n  border-radius: var(--border-radius, 6px);\n  box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);\n  margin-bottom: 1rem;\n}\n\n.Cell {\n  padding: 0.5rem;\n  text-align: inherit;\n  text-align: -webkit-match-parent;\n}\n\n.Th {\n  @extend .Cell;\n  background-color: #eee;\n}\n\n.Td {\n  @extend .Cell;\n}\n\n.NoSpaces {\n  text-align: center;\n}\n\n.AddSpaceContainer {\n  margin: 1rem 0;\n}\n\n.AddSpaceForm {\n  display: flex;\n  gap: 1rem;\n}\n\n.AddSpaceDropdown {\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": "wPmkXj7ACUBw19UaF2dv",
	"Table": "i32YheiFqzahVPb5XMae",
	"Cell": "T6Z7mBDfin81edVhFKgQ",
	"Td": "j7iEPS0AIlLrfdLaHSzK",
	"Th": "lRp6DBd_QlSexPcHFhEy",
	"NoSpaces": "jnAuQI56KzUUQTL_JPjv",
	"AddSpaceContainer": "j1of7YHmPSl_kdBTUQb_",
	"AddSpaceForm": "vsCersdkOx96fAQJjxUv",
	"AddSpaceDropdown": "gs7_vdFEuKDfHs_xMriu"
};
export default ___CSS_LOADER_EXPORT___;
