import { Button, FormControl, FormErrorText, Heading, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utils/hooks/useWorkspace'

import { useAppDetailContext } from '../../AppDetailContext'
import gitbookAuthFormValidationSchema from './gitbook-auth-form-validation-schema'

const { default: api } = require('Src/services/api')

const GitBookAuthForm = ({ onCancel, onAuth, app }) => {
  const { currentWorkspaceId } = useWorkspace()
  const { title } = useAppDetailContext()
  const [connectGitBook, connectGitBookResult] = api.useConnectGitBookAppMutation()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty }
  } = useForm({
    resolver: yupResolver(gitbookAuthFormValidationSchema)
  })

  const handleConnect = async (data) => {
    const payload = {
      workspace_id: currentWorkspaceId,
      access_token: data.access_token
    }

    try {
      const promise = connectGitBook(payload).unwrap()

      toast.promise(promise, {
        loading: `Connecting to ${title}`,
        success: `Successfully connected to ${title}`,
        error: (err) => {
          const errorMessage = err?.data?.error || `Unable to connect to ${title}. Try again...`
          return errorMessage
        }
      })
      await promise
      onAuth()
    } catch {}
  }

  const disableSubmitButton = isSubmitting || !isDirty || connectGitBookResult.isLoading

  return (
    <Fragment>
      <Heading level={2}>Connect to {title}</Heading>
      <form onSubmit={handleSubmit(handleConnect)}>
        <FormControl isInvalid={errors.access_token}>
          <Label>API Key</Label>
          <Input {...register('access_token')} />
          {errors.access_token && <FormErrorText>{errors.access_token.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Connect
        </Button>
        <Button variant='link-muted' onClick={onCancel}>
          Cancel
        </Button>
      </form>
    </Fragment>
  )
}

export default GitBookAuthForm
