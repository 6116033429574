import cx from 'classnames'
import { useState } from 'react'

import styles from './Brand.module.css'

import ActiveHappyfoxLogo from 'Images/happyfox-logo-active.svg'
import DefaultHappyfoxLogo from 'Images/happyfox-logo-default.svg'

const Brand = () => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className={styles.BrandContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={cx(styles.LogoWrapper, { [styles.Hovered]: isHovered })}>
        <div className={styles.LogoBase}>
          <DefaultHappyfoxLogo className={styles.Logo} />
        </div>

        <div className={cx(styles.LogoOverlay, { [styles.Visible]: isHovered })}>
          <ActiveHappyfoxLogo className={styles.Logo} />
        </div>
      </div>

      <h2 className={styles.BrandName}>Assist AI</h2>
    </div>
  )
}

export default Brand
